<template>
  <div>
    <div class="d-flex sticky-top bg-white mb-2" style="z-index: 2">
      <button class="btn btn-light" @click="closeEmail">
        <i class="far fa-arrow-left me-1" />Back</button>
      <button class="btn btn-primary ms-auto" @click="showReply=true">
        <i class="far fa-reply me-1" />Reply</button>
    </div>
    <div v-for="email in thread.messages" class="mb-3">
      <div class="card bg-light border-0 mb-3">
        <div class="card-body p-0 p-md-3">
          <div class="card bg-white border-0 shadow-none mb-2">
            <div class="card-body">
              <div class="d-flex w-100 mb-3">
                <h5 class="fw-bold my-auto">{{email.subject}}</h5>
                <div class="ms-auto">{{email.sent_date | formatDate}}</div>
              </div>

              <div class="form-group row mb-2">
                <label class="col-lg-1 col-form-label">From</label>
                <div class="col-lg-11">
                  <input type="text" class="form-control d-inline" disabled :value="email.from" />
                </div>
              </div>
              <div class="form-group row mb-2">
                <label class="col-lg-1 col-form-label">To</label>
                <div class="col-lg-11">
                  <input type="text" class="form-control d-inline" disabled :value="explodeArray(email.to)" />
                </div>
              </div>
              <div class="form-group row mb-0" v-if="email.cc">
                <label class="col-lg-1 col-form-label">cc</label>
                <div class="col-lg-11">
                  <input type="text" class="form-control d-inline" disabled :value="explodeArray(email.cc)" />
                </div>
              </div>
            </div>
          </div>
          <div class="card bg-white shadow-none">
            <div class="card-body">
              <div class="w-100">
                <iframe class="border rounded" style="overflow: scroll" :srcdoc="email.body" width="100%" height="500px">

                </iframe>
              </div>
            </div>
          </div>
          <div class="card bg-white shadow-none" v-if="email.contentType === 'order' && email.contentId">
            <div class="card-body">
              <div class="form-group row mb-2">
                <label class="col-lg-3 col-form-label">Attachment</label>
                <button class="btn btn-light" @click="generateClientInvoice(email.contentId)">
                  See invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <compose-email-modal v-if="showReply"
                         @closed="closeReplY"
                         :reply-to="thread"
                         :contact="thread.contact"/>

  </div>
</template>

<script>

import ComposeEmailModal from "@/views/components/inbox/ComposeEmailModal";
export default {
  components: {ComposeEmailModal},
  props: ["thread"],
  data() {
    return {
      value: this.thread,
      showReply: false
    };
  },
  methods: {
    generateClientInvoice(orderId) {
      window.open('/orders/' + orderId);
    },
    closeEmail() {
      this.showReply = false;
      this.$emit("closed");
    },
    closeReplY() {
      this.showReply = false;
    },
    markAsRead() {
      if (this.thread.source === 'gmail') {
        this.$google.api.client.request({
          path: 'gmail/v1/users/me/threads/' + this.value.thread_id + '/modify',
          method: 'POST',
          params: {
            removeLabelIds: ["UNREAD"]
          }
        }).then((response) => {
          this.thread.read = true;
          this.thread.unread_count = 0;
        });
      } else {
        this.thread.messages.forEach((email) => {
          email.read = true;
          this.$axios.put(process.env.VUE_APP_API_URL + '/emails/' + email.id, email);
        })
        this.thread.read = false;
        this.thread.unread_count = 0;
      }
      this.$emit("read");
    },
    explodeArray(arrayToExplode) {
      if (arrayToExplode) {
        if (typeof arrayToExplode === "string") { arrayToExplode = JSON.parse(arrayToExplode)}
        let str = "";
        arrayToExplode.forEach((item, index) => {
          if (index > 0) {
            str = str + ", "
          }
          str = str + item;
        })
        return str;
      }
      return "";
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    }
  },
  mounted() {
    this.markAsRead();
  }
};
</script>

<style>
</style>
