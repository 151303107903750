<template>
  <div
    class="modal fade"
    id="compose-email-modal"
    ref="composeEmailModal"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div
      class="
        modal-dialog modal-lg modal-dialog-centered modal-fullscreen-md-down
      "
    >
      <div class="modal-content">
        <div class="modal-body">
          <div v-if="contact" class="d-flex mb-3">
            <div class="my-auto me-1">
              <img
                :src="contact.profile_pic_url"
                alt="contact"
                width="32"
                height="32"
                class="rounded-circle me-2"
              />
            </div>
            <div>
              {{ contact.name }}
              <span class="my-0 d-block fst-italic small">{{
                contact.email
              }}</span>
            </div>
          </div>

          <form @submit.prevent="sendEmail">
            <input
              type="text"
              class="form-control mb-3"
              placeholder="Subject..."
              v-model="form.subject"
              required
            />
            <hr />

            <div class="row">
              <div class="col">
                <div class="form-group mb-0">
                  <label class="d-inline">To</label>
                  <multiselect
                    v-model="form.to"
                    :options="contactSearchResults"
                    label="email"
                    track-by="email"
                    tag-placeholder="Add email address"
                    placeholder="Search contacts or enter email address"
                    :multiple="true"
                    :taggable="true"
                    :show-no-results="false"
                    :internal-search="false"
                    @search-change="performSearchContacts"
                    @tag="addEmailToList"
                  >
                    <template slot="option" slot-scope="props">
                      <div v-if="props.option.id">
                        {{ props.option.name }}
                        <i v-if="props.option.email" class="small"
                          >({{ props.option.email }})</i
                        >
                      </div>
                    </template>

                    <template slot="caret">
                      <div class="d-none"></div>
                    </template>

                    <template slot="noOptions">
                      <i class="small">Start typing...</i>
                    </template>
                  </multiselect>

                  <p class="text-danger" v-if="showInvalidToAddress">
                    Please enter a valid email address or search for a contact.
                  </p>
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-0">
                  <label class="d-inline">Cc</label>
                  <multiselect
                    v-model="form.cc"
                    :options="contactSearchResults"
                    label="email"
                    track-by="email"
                    tag-placeholder="Add email address"
                    placeholder="Search contacts or enter email address"
                    :multiple="true"
                    :taggable="true"
                    :show-no-results="false"
                    :internal-search="false"
                    @search-change="performSearchContacts"
                    @tag="addEmailToCcList"
                  >
                    <template slot="option" slot-scope="props">
                      <div v-if="props.option.id">
                        {{ props.option.name }}
                        <i v-if="props.option.email" class="small"
                          >({{ props.option.email }})</i
                        >
                      </div>
                    </template>

                    <template slot="caret">
                      <div class="d-none"></div>
                    </template>

                    <template slot="noOptions">
                      <i class="small">Start typing...</i>
                    </template>
                  </multiselect>

                  <p class="text-danger" v-if="showInvalidCcAddress">
                    Please enter a valid email address or search for a contact.
                  </p>
                </div>
              </div>
            </div>

            <hr />

            <textarea
              rows="10"
              v-model="form.body"
              class="w-100"
              required
            ></textarea>

            <div class="row mt-3">
              <div class="col text-end">
                <button
                  class="btn btn-light me-2"
                  data-bs-dismiss="modal" data-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="far fa-times me-1" />Cancel
                </button>
                <button class="btn btn-primary" :disabled="sending">
                  <busy :visible="sending" button />
                  <i v-if="!sending" class="far me-1 fa-paper-plane" />
                  Send Email
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import Multiselect from "vue-multiselect";
import mimetext from "mimetext";

import { Modal } from "bootstrap";

export default {
  props: ["contact", "replyTo", "hideSubject"],
  components: { Multiselect },
  data() {
    return {
      modal: null,
      sending: false,
      form: {
        reply_to_email_id: this.replyTo ? this.replyTo.id : null,
        client_id: this.contact ? this.contact.id : null,
        subject: this.replyTo ? "RE: " + this.replyTo.subject : "",
        body: "",
        to: this.replyTo
          ? this.arrayOfEmailsToArrayOfObjects(this.replyTo.messages[0].to)
          : [],
        cc: this.replyTo
          ? this.arrayOfEmailsToArrayOfObjects(this.replyTo.messages[0].cc)
          : [],
      },
      contactSearchResults: [],
      showInvalidToAddress: false,
      showInvalidCcAddress: false,
    };
  },
  computed: {},
  methods: {
    sendEmail() {
      if (this.$stash.gmail.signedIn) {
        this.sending = true;
        const message = mimetext;

        message.setSender({
          name: this.$store.getters['auth/user'].name,
          addr: this.$stash.gmail.emailAddress,
        });

        this.form.to.forEach((item) => {
          message.setRecipient(item.email);
        });

        let ccs = [];
        this.form.cc.forEach((item) => {
          ccs.push({
            addr: item.email,
          });
        });
        message.setRecipient(ccs, { cc: true });

        message.setSubject(this.form.subject);
        message.setMessage(this.form.body);

        if (this.replyTo) {
          message.setHeaders({
            "In-Reply-To": this.getHeaderVal(
              "Message-Id",
              this.replyTo.messages[0]
            ),
          });
        }

        let sendRequest = this.$google.api.client.gmail.users.messages.send({
          userId: "me",
          resource: {
            raw: message.asEncoded(),
          },
        });

        sendRequest.execute(({ response }) => {
          this.$EventBus.$emit("alert", { message: "Sent" });
          this.$EventBus.$emit("updatedEmails");
          this.closeModal();
          this.sending = false;
        });
      } else {
        this.sending = true;
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/emails", this.form)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$EventBus.$emit("updatedEmails");
            this.closeModal();
            this.sending = false;
          });
      }
    },
    searchContacts(query) {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/fetch-index?paginate=false&search=" +
            query
        )
        .then(({ data }) => {
          this.contactSearchResults = data.data;
        });
    },
    performSearchContacts: debounce(function (query) {
      this.searchContacts(query);
    }, 300),
    closeModal() {
      this.modal.hide();
    },
    addEmailToList(newTag) {
      if (this.validateEmail(newTag)) {
        this.contactSearchResults.push({ email: newTag });
        this.form.to.push({ email: newTag });
      } else {
        const vm = this;
        vm.showInvalidToAddress = true;
        setTimeout(function () {
          vm.showInvalidToAddress = false;
        }, 1500);
      }
    },
    addEmailToCcList(newTag) {
      if (this.validateEmail(newTag)) {
        this.contactSearchResults.push({ email: newTag });
        this.form.cc.push({ email: newTag });
      } else {
        const vm = this;
        vm.showInvalidCcAddress = true;
        setTimeout(function () {
          vm.showInvalidCcAddress = false;
        }, 1500);
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    arrayOfEmailsToArrayOfObjects(inputArray) {
      let ret = [];
      inputArray.forEach((item) => {
        ret.push({ email: item });
      });
      return ret;
    },
  },
  watch: {},
  mounted() {
    this.modal = new Modal(this.$refs.composeEmailModal);
    this.modal.show();

    const vm = this;
    this.$refs.composeEmailModal.addEventListener("hide.bs.modal", function () {
      vm.$emit("closed");
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
